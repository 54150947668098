<template>
  <Header title="عن الشركة" link="عن الشركة" />
  <div class="relative z-30 md:-top-20 w-full -mb-20">
    <div class="container mx-auto px-5 md:px-8 text-right">
      <div
        class="flex items-center md:-mb-8 justify-center mt-8 flex-wrap md:flex-nowrap md:justify-between tracking-wide leading-relaxed"
      >
        <div class="">
          <h3 class="text-black text-xl font-bold my-5">عن الشركة</h3>
          <p class="my-2 text-infoGray text-md">
            شركة زين للخدمات التموينية والسفرجة والنظافة هي من إحدى الشركات
            الرائدة في هذا المجال بخبرات محلية واجنبية.نحن نعتمد في منهجنا
            وأسلوب عملنا على تبادل الثقة والتفاهم و الانسجام لأننا نؤمن إيمانا
            عميقا بأن هذا ما سيؤدي الى تحقيق النجاح وتحقيق المنافع المتبادلة
            التي من شأنها أن تعزز المزيد من الأعمال التجارية بين الطرفين مستقبلا
            والتي تستمر لفترة طويلة.
          </p>
          <p class="my-2 text-infoGray text-md">
            خدمات شركة زين لا تشمل فقط خدمات التموين الغذائي للمؤسسات والمنظمات
            و مواقع العمل ، ولكن أيضا تشمل التنظيف والغسيل و الصيانة وتوفير
            الطاقة البشرية.
          </p>
          <p class="my-2 text-infoGray text-md">
            نحن نتابع دائماً الجديد والحديث في مجال عمل الشركة لنكون دائماً
            جاهزين وقادرين لإيصال خدماتنا وتوزيعها لعملائنا في الوقت المناسب،
            ونحن نقدم خدماتنا للجهات التالية:
          </p>
        </div>
        <img
          class="md:-mt-28 object-contain"
          src="../assets/aboutcad2.png"
          alt=""
        />
      </div>
      <div class="mb-16">
        <h3 class="text-black text-xl font-bold my-5">الجهات المستهدفة</h3>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-8 my-8">
          <div
            class="flex items-center"
            v-for="(item, index) in targetPlanes"
            :key="index"
          >
            <span class="block rounded-md bg-primary h-4 w-4 mx-2"></span>
            <span class="text-black font-medium">{{ item }}</span>
          </div>
        </div>
      </div>
      <span class="block w-full h-1 bg-tertiary rounded-md my-8"></span>
      <div class="my-12 grid grid-cols-12 gap-8 md:gap-12">
        <div
          class="col-span-12 md:col-span-2 flex md:block md:justify-between md:items-center"
        >
          <router-link
            :class="$route.name == 'Tasks' ? 'border border-primary' : null"
            class="w-full p-3 md:p-5 rounded-md shadow-lg block m-5"
            :to="{ name: 'Tasks' }"
          >
            <img class="mx-auto" src="../assets/target.svg" alt="" />
            <h3 class="text-center mt-3">المهام والقيم</h3>
          </router-link>
          <router-link
            :class="
              $route.name == 'Principles' ? 'border border-primary' : null
            "
            class="w-full p-3 md:p-5 rounded-md shadow-lg block m-5"
            :to="{ name: 'Principles' }"
          >
            <img class="mx-auto" src="../assets/wealth.svg" alt="" />
            <h3 class="text-center mt-3">مبادئ العمل</h3>
          </router-link>
          <router-link
            :class="$route.name == 'Structure' ? 'border border-primary' : null"
            class="w-full p-3 md:p-5 rounded-md shadow-lg block m-5"
            :to="{ name: 'Structure' }"
          >
            <img
              class="mx-auto"
              src="../assets/organization-chart.svg"
              alt=""
            />
            <h3 class="text-center mt-3">الهيكل التنظيمي</h3>
          </router-link>
        </div>
        <div class="col-span-12 md:col-span-10">
          <router-view />
        </div>
      </div>
      <span class="block w-full h-1 bg-tertiary rounded-md"></span>
    </div>
    <Services />
  </div>
  <ContactLink />
</template>
<script>
import { defineAsyncComponent } from "vue";
const ContactLink = defineAsyncComponent(() =>
  import("../components/ContactLink")
);
const Header = defineAsyncComponent(() => import("../components/Header"));
const Services = defineAsyncComponent(() =>
  import("../components/Home/Services")
);

export default {
  name: "About",
  components: { ContactLink, Header, Services },
  data() {
    return {
      targetPlanes: [
        "الشركات والمؤسسات الوطنية والأجنبية ",
        "الفنادق والمقاهي والموانئ والسفن والطائرات",
        "الجامعات والمعاهد والمدارس والأقسام الداخلية.",
        "الحقول النفطية والمخيمات الصحراوية .",
        "المستشفيات والمصحات ودور الرعاية.",
        "السفارات والجهات الرسمية.",
      ],
    };
  },
  mounted(){
    this.$router.push({name: 'Tasks'})
  }
};
</script>
